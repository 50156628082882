define("discourse/plugins/discourse-locations/discourse/components/location-selector", ["exports", "jquery", "discourse/components/text-field", "discourse/lib/decorators", "discourse/lib/utilities", "discourse-i18n", "discourse/plugins/discourse-locations/discourse/lib/location-utilities"], function (_exports, _jquery, _textField, _decorators, _utilities, _discourseI18n, _locationUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _textField.default.extend(dt7948.p({
    autocorrect: false,
    autocapitalize: false,
    classNames: "location-selector",
    context: null,
    size: 400,
    didInsertElement() {
      this._super();
      let self = this;
      const location = this.get("location.address");
      let val = "";
      if (location) {
        val = location;
      }
      (0, _jquery.default)(self.element).val(val).autocomplete({
        template: locationAutocompleteTemplate,
        single: true,
        updateData: false,
        dataSource: function (term) {
          let request = {
            query: term
          };
          const context = self.get("context");
          if (context) {
            request["context"] = context;
          }
          self.set("loading", true);
          return (0, _locationUtilities.geoLocationSearch)(request, self.siteSettings.location_geocoding_debounce).then(result => {
            if (result.error) {
              throw new Error(result.error);
            }
            const defaultProvider = self.siteSettings.location_geocoding_provider;
            const geoAttrs = self.get("geoAttrs");
            const showType = self.get("showType");
            let locations = [];
            if (!result.locations || result.locations.length === 0) {
              locations = [{
                no_results: true
              }];
            } else {
              locations = result.locations.map(l => {
                if (geoAttrs) {
                  l["geoAttrs"] = geoAttrs;
                }
                if (showType !== undefined) {
                  l["showType"] = showType;
                }
                return l;
              });
            }
            locations.push({
              provider: _locationUtilities.providerDetails[result.provider || defaultProvider]
            });
            self.set("loading", false);
            return locations;
          }).catch(e => {
            self.set("loading", false);
            this.searchError(e);
          });
        },
        transformComplete: function (l) {
          if (typeof l === "object") {
            self.onChangeCallback(l);
            const geoAttrs = self.get("geoAttrs");
            return (0, _locationUtilities.geoLocationFormat)(l, self.site.country_codes, {
              geoAttrs
            });
          } else {
            // hack to get around the split autocomplete performs on strings
            document.querySelectorAll(".location-form .ac-wrap .item").forEach(element => {
              element.remove();
            });
            document.querySelectorAll(".user-location-selector .ac-wrap .item").forEach(element => {
              element.remove();
            });
            return self.element.value;
          }
        },
        onChangeItems: function (items) {
          if (items[0] == null) {
            self.set("location", "{}");
          }
        }
      });
    },
    showLoadingSpinner() {
      const loading = this.get("loading");
      const wrap = this.element.parentNode;
      const spinner = document.createElement("span");
      spinner.className = "ac-loading";
      spinner.innerHTML = "<div class='spinner small'></div>";
      if (loading) {
        wrap.insertBefore(spinner, wrap.firstChild);
      } else {
        const existingSpinner = wrap.querySelectorAll(".ac-loading");
        existingSpinner.forEach(el => el.remove());
      }
    },
    willDestroyElement() {
      this._super();
      (0, _jquery.default)(this.element).autocomplete("destroy");
    }
  }, [["method", "showLoadingSpinner", [(0, _decorators.observes)("loading")]]]));
  function locationAutocompleteTemplate(context) {
    const optionHtml = context.options.map(o => {
      if (o.no_results) {
        return `<div class="no-results">${(0, _discourseI18n.i18n)("location.geo.no_results")}</div>`;
      } else if (o.provider) {
        return `<label>${(0, _discourseI18n.i18n)("location.geo.desc", {
          provider: o.provider
        })}</label>`;
      } else {
        const typeHtml = o.showType ? `<div class="location-type">${(0, _utilities.escapeExpression)(o.type)}</div>` : "";
        return `
        <li class="location-form-result">
          <label>${(0, _utilities.escapeExpression)(o.address)}</label>
          ${typeHtml}
        </li>`;
      }
    });
    return `<div class="autocomplete"><ul>${optionHtml.join("")}</ul></div>`;
  }
});